.h1{
    font-size: 40px;
    font-family: "AvenirNext LT Pro Bold";
}
p{
    font-size: 28px;
    font-family: 'AvenirNext LT Pro Bold';
    font-weight: 400;
}
.hs{
    font-size: 35px;
    font-family: "AvenirNext LT Pro Bold";
}
.font{
    font-style: italic;
    font-weight: 300;
}
nav {
	position: relative;
	z-index: 1;
	padding-left: 1em;
}
 
.tab {
  position: relative;
	display: inline-block;
	color: inherit;
	text-decoration: none;
	padding: .3em 1em 0;
	margin: 0 -.3em;
}

.tab::before,
.main {
  border: .2em solid white;
}

.tab::before {
  content: ''; /* To generate the box */
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: -1;
	border-bottom: none;
	border-left: .2em solid white; /* Add left border */
	border-radius: .1em 0 0 0; /* Make the right side straight */
	border-right: .50em solid white;
    border-top: 4px solid white;
	transform: scale(1.1, 1.3) perspective(.5em) rotateX(5deg);
	transform-origin: bottom left; /* Change the origin to bottom left */
}

.tab.selected { z-index: 4;}

.tab.selected::before {
	background-color: #003366;
	margin-bottom: -.2em;
}

.main {
	display: block;
	margin-bottom: 1em;
	background: #003366;
	border-radius: .15em;
	margin-top: 1rem;
}

nav.left > .tab::before {
	transform: scale(1.2, 1.3) perspective(.5em) rotateX(5deg);
	transform-origin: bottom left;
	border-radius: .5em 0 0 0; /* Ensure left side is straight */
}

nav.right { padding-left: 2em; }

nav.right > .tab::before {
	transform: scale(1.2, 1.3) perspective(.5em) rotateX(5deg);
	transform-origin: bottom left; /* Change the origin to bottom left */
	border-radius: .5em 0 0 0; /* Ensure right side is straight */
	border-left: .1em solid rgba(0,0,0,.4); /* Add left border */
}
