@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'AvenirNext LT Pro Bold';
}
.next{
  font-family: 'AvenirNext LT Pro Bold';
}
#more{
  display: none;
}


@font-face {
  font-family: 'AvenirNext LT Pro Bold';
  src: url('./fonts/ZtNeztoDEMO-Bold.ttf') format('truetype');
  /* Add other font properties like font-weight, font-style if needed */
}